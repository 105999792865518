/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        var menuLeft = $( '#mobileMenu' ),
		menuKnop = $( '#pushLeft' ),
		body = $(document.body);
          
        menuKnop.on("click", function() {
            $(this).toggleClass( "active" );
            $(body).toggleClass( "slideVerticalPush-toRight" );
            $(menuLeft).toggleClass( "inFromLeft-open" );
        });
        
        $('nav.nav-primary').affix({
          offset: {
            top: 162,
          }
        });
          
        $('.wpcf7-select').chosen({
            width: "100%",
        });
        
        $('.optioneel2').popover({
            trigger: 'hover',
            html: true,
            content: function() {
                return $(this).parent().next('.optioneel2_popover_content:hidden:first').html();
            }
        });
          
        $('.vervolg_featured img').addClass('img-responsive');
        
        $('div.formulier_keuze span.wpcf7-list-item:nth-child(1) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.contact-email').hide();
                $('.row.contact-telefoon').hide();
                $('.row.brochure-email').hide();
                
                $('.submit-contact-email').hide();
                $('.submit-brochure-email').hide();
                $('.submit-contact-telefoon').hide();
                
                $('.row.brochure-post').fadeIn('fast');
                $('.submit-brochure-post').fadeIn('fast');
            }
        });
          
        $('div.formulier_keuze span.wpcf7-list-item:nth-child(2) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.contact-email').hide();
                $('.row.contact-telefoon').hide();
                $('.row.brochure-post').hide();
                
                $('.submit-contact-email').hide();
                $('.submit-brochure-post').hide();
                $('.submit-contact-telefoon').hide();
                
                $('.row.brochure-email').fadeIn('fast');
                $('.submit-brochure-email').fadeIn('fast');
            }
        });
          
        $('div.formulier_keuze span.wpcf7-list-item:nth-child(3) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.contact-email').hide();
                $('.row.brochure-post').hide();
                $('.row.brochure-email').hide();
                
                $('.submit-brochure-post').hide();
                $('.submit-brochure-email').hide();
                $('.submit-contact-email').hide();
                
                $('.row.contact-telefoon').fadeIn('fast');
                $('.submit-contact-telefoon').fadeIn('fast');
            }
        });
          
        $('div.formulier_keuze span.wpcf7-list-item:nth-child(4) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.contact-telefoon').hide();
                $('.row.brochure-post').hide();
                $('.row.brochure-email').hide();
                
                $('.submit-brochure-post').hide();
                $('.submit-brochure-email').hide();
                $('.submit-contact-telefoon').hide();
                
                $('.row.contact-email').fadeIn('fast');
                $('.submit-contact-email').fadeIn('fast');
            }
        });
          
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'pakketten': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      'finalize': function() {
        $('.pakketinfo').popover({
            placement: 'bottom',
            trigger: 'hover'
        });

        var modalTitel = $('.remodal .gekozen h1').text();
        var modalPakket = $('.remodal .gekozen .pakketnaam').text();
        var modalPrijs = $('.remodal .gekozen .pakketprijs').text();

        $('.pakket .keuze_blauw').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });

        $('.keuze_geel').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.first label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item:nth-child(2) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wa_verzekering').fadeOut('fast');
                $('.row.wacasco_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.last label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeOut('fast');
            }
        });
          
      }
    },
    // About us page, note the change from about-us to about_us.
    'uitgebreid': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      'finalize': function() {
        $('.pakketinfo').popover({
            placement: 'bottom',
            trigger: 'hover'
        });

        var modalTitel = $('.remodal .gekozen h1').text();
        var modalPakket = $('.remodal .gekozen .pakketnaam').text();
        var modalPrijs = $('.remodal .gekozen .pakketprijs').text();

        $('.keuze_blauw').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });

        $('.keuze_geel').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.first label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item:nth-child(2) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wa_verzekering').fadeOut('fast');
                $('.row.wacasco_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.last label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeOut('fast');
            }
        });
          
      }
    },
    // About us page, note the change from about-us to about_us.
    'basis': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      'finalize': function() {
        $('.pakketinfo').popover({
            placement: 'bottom',
            trigger: 'hover'
        });

        var modalTitel = $('.remodal .gekozen h1').text();
        var modalPakket = $('.remodal .gekozen .pakketnaam').text();
        var modalPrijs = $('.remodal .gekozen .pakketprijs').text();

        $('.keuze_blauw').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });

        $('.keuze_geel').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.first label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item:nth-child(2) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wa_verzekering').fadeOut('fast');
                $('.row.wacasco_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.last label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeOut('fast');
            }
        });
          
      }
    },
    // About us page, note the change from about-us to about_us.
    'optimaal': {
      init: function() {
        // JavaScript to be fired on the about us page
      },
      'finalize': function() {
        $('.pakketinfo').popover({
            placement: 'bottom',
            trigger: 'hover'
        });

        var modalTitel = $('.remodal .gekozen h1').text();
        var modalPakket = $('.remodal .gekozen .pakketnaam').text();
        var modalPrijs = $('.remodal .gekozen .pakketprijs').text();

        $('.keuze_blauw').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });

        $('.keuze_geel').click(function(){
            var pakket = $(this).attr("data-gekozen");
            var prijs = $(this).attr("data-prijs");
            var nieuweTitel = modalTitel + ' ' + pakket;
            var nieuwePakketNaam = modalPakket + ' <span class="pakket_type">' + pakket + '</span>';
            var nieuwePrijs = modalPrijs + ' <span class="pakket_prijs">€ ' + prijs + ' p/m</span>';
            $('.remodal .gekozen h1').text(nieuweTitel);
            $('.remodal .gekozen .pakketnaam').html(nieuwePakketNaam);
            $('.remodal .gekozen .pakketprijs').html(nieuwePrijs);
            $('span.pakket input').val(pakket);
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.first label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item:nth-child(2) label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wa_verzekering').fadeOut('fast');
                $('.row.wacasco_verzekering').fadeIn('fast');
            }
        });
          
        $('span.wa-verzekering span.wpcf7-list-item.last label input').click(function(){
            if($(this).is(':checked')) {
                $('.row.wacasco_verzekering').fadeOut('fast');
                $('.row.wa_verzekering').fadeOut('fast');
            }
        });
          
      }
    },
    'bedankt_pakket': {
      init: function() {
        
      },
      finalize: function() {
           var getUrlParameter = function getUrlParameter(sParam) {
                var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                    sURLVariables = sPageURL.split('&'),
                    sParameterName,
                    i;

                for (i = 0; i < sURLVariables.length; i++) {
                    sParameterName = sURLVariables[i].split('=');

                    if (sParameterName[0] === sParam) {
                        return sParameterName[1] === undefined ? true : sParameterName[1];
                    }
                }
            };
          
          var actie = getUrlParameter('actie');
          
          if(actie === 'brochure-email') {
              $('#bedankt-brochure-email').show();
          } else if(actie === 'brochure-post') {
              $('#bedankt-brochure-post').show();
          } else if(actie === 'contact-email') {
              $('#bedankt-contact-email').show();
          } else if(actie === 'contact-telefoon') {
              $('#bedankt-contact-telefoon').show();
          }
                    
          /*console.log();
          if(location.search == "?actie=brochure-email"){
              $('#bedankt-brochure-email').show();
          }*/
          
      }
    },  
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
